import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom"; 

export default function ProjectNavigation() {
  const navigate = useNavigate();

  const projects = ["devcollab", "dinefusion"];
  const currentProject = window.location.pathname.split('/').pop();
  let currentIndex = projects.indexOf(currentProject);

  const nextWrapperVariants = {
    initial: {
      borderRight: "2px solid rgba(7, 139, 255, 1)",
      padding: "0px",
    },
    animate: {
      borderRight: "2px solid rgba(7, 139, 255, 0)",
      padding: "10px",
      transition: {
        duration: 1,
        ease: "easeInOut",
        repeat: Infinity,
      },
    },
  };

  const prevWrapperVariants = {
    initial: {
      borderLeft: "2px solid rgba(7, 139, 255, 1)",
      padding: "0px",
    },
    animate: {
      borderLeft: "2px solid rgba(7, 139, 255, 0)",
      padding: "10px",
      transition: {
        duration: 1,
        ease: "easeInOut",
        repeat: Infinity,
      },
    },
  };

  const stableVariants = {
    initial: {
      margin: "10px",
    },
    animate: {
      margin: "0px",
      transition: {
        duration: 1,
        ease: "easeInOut",
        repeat: Infinity,
      },
    },
  };

  const handleNext = () => {
    currentIndex = (currentIndex + 1) % projects.length;
    navigate(`/projects/${projects[currentIndex]}`);
  };

  const handlePrev = () => {
    currentIndex = (currentIndex - 1 + projects.length) % projects.length;
    navigate(`/projects/${projects[currentIndex]}`);
  };

  return (
    <div className="project-navigation-wrapper">
      <motion.div
        className="project-navigation"
        variants={stableVariants}
        initial="initial"
        animate="animate"
      >
        <motion.div
          className="prev-handle-wrapper"
          variants={prevWrapperVariants}
          initial="initial"
          animate="animate"
          onClick={handlePrev}
        >
          <div className="prev-handle">👈</div>
        </motion.div>
        <motion.div
          className="next-handle-wrapper"
          variants={nextWrapperVariants}
          initial="initial"
          animate="animate"
          onClick={handleNext}
        >
          <div className="next-handle">👉</div>
        </motion.div>
      </motion.div>
    </div>
  );
}
