import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function ProjectSection({ children }) {
  const [ref, inView] = useInView({
    threshold: 0.05,
    triggerOnce: true,
  });

  const upwardMotion = {
    initial: {
      y: 50,
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.7,
        ease: "easeIn",
      },
    },
  };
  const wrapperVariants = {
    initial: {
      border: "2px solid rgba(15, 185, 15, 1)",
      padding: "0px",
    },
    animate: {
      border: "2px solid rgba(15, 185, 15,0)",
      padding: "15px",
      transition: {
        duration: 1,
        ease: "easeInOut",
        repeat: Infinity,
        // repeatType: "reverse",
      },
    },
  };

  const stablilityVariants = {
    initial: {
       padding: '15px'
    },
    animate: {
      padding: '0px',
      transition: {
        duration: 1,
        ease: "easeInOut",
        repeat: Infinity,
        // repeatType: "reverse",
      },
    },
  };

  return (
    <motion.div
      className="project-section-wrapper"
      ref={ref}
      variants={upwardMotion}
      initial="initial"
      animate={(inView)? "animate" : "initial"}
    >
      <motion.div
        className="stable-wrapper"
        variants={stablilityVariants}
        initial="initial"
        animate="animate"
      >
        <motion.div
          className="waves-animation"
          variants={wrapperVariants}
          initial="initial"
          animate="animate"
        >
          <div className="project-section">
             {children}
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}

