import React from 'react'
import '../../styles/introStyles.css'

export default function Intro() {
  return (
    <div className="intro-wrapper">
    <div className='intro'>
        <div className="hello-section">
             👋Hey there, I am Manas Srivastava
        </div>
        <div className="highlight-intro">
            <h1> Full Stack Web-Developer </h1>
        </div>
        <div className="extra-intro">
            I am a Full Stack Web-Developer with a passion for creating beautiful and functional websites.
        </div>
    </div>
    </div>
  )
}
