import React from 'react'
import ProjectSubHeading from "../../../projectSubHeading";

export default function TechStack() {
  return (
    <>
      <ProjectSubHeading  subHeading={"Tech Stack"}/>
      <div className="techStack project-text">
         <div className="techStack-item">
            <h3>Front-End</h3>
            <ul>
                <li>PugJs</li>
                <li>CSS</li>
                <li>JavaScript</li>
                <li>Bootstrap</li>
            </ul>
         </div>
         <div className="techStack-item">
            <h3>Back-End</h3>
            <ul>
                <li>NodeJs</li>
                <li>Express</li>
            </ul>
         </div>
         <div className="techStack-item">
            <h3>Database</h3>
            <ul>
                <li>MongoDB</li>
            </ul>
         </div>
         {/* <div className="techStack-item">
            <h3>Email Service</h3>
            <ul>
                <li>Nodemailer</li>
            </ul>
         </div> */}
      </div>
    </>
  )
}
