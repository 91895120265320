import React from "react";
import ProjectSubHeading from "../../../projectSubHeading";

export default function Features() {
  return (
    <>
      <ProjectSubHeading subHeading="Key Features" />
      <div className="features-wrapper project-text">
        <div className="features">
          <div className="feature">
            <h3>User Authentication & Account Management</h3>
            <ul>
              <li>
                <strong>Welcome Page:</strong> A user-friendly landing page with
                options to log in or create a new account.
              </li>
              <li>
                <strong>Account Creation:</strong> Users can create a new
                account using a traditional signup form or sign in with their
                Google account.
              </li>
              <li>
                <strong>Forgot Password:</strong> Users can request a password
                reset link via email.
              </li>
              <li>
                <strong>Profile Customization:</strong> Users can easily update
                their profiles with our intuitive image cropper for precise
                adjustments.
              </li>
            </ul>
          </div>

          <div className="feature">
            <h3>Project Management</h3>
            <ul>
              <li>
                <strong>Start with Project:</strong> You can easily create a
                project just by entering name, we will set up a initial template
                for you or you can upload a project directory (if your browser
                supports to upload directory)
              </li>
              <li>
                <strong>File System Management:</strong> Easily create file or
                upload a file and same create a folder or upload a folder (again
                if you browser supports to upload directory). Easily rename or
                delete file or folder
              </li>
              <li>
                <strong>View Projects:</strong> View all your projects in view
                projects section
              </li>
              <li>
                <strong>Code Editor:</strong> A vs-code like code editor with
                suggestions, auto complete, shortcuts and ofcourse syntax
                highlighting.
              </li>
            </ul>
          </div>

          <div className="feature">
            <h3>Collaboration</h3>
            <ul>
              <li>
                <strong>Send and receive invite:</strong> Send and receive
                invitations, view all invite request in invitation section and
                you can send invite by going to manage collaborator section of
                your project.
              </li>
              <li>
                <strong>Real Time Updates:</strong> File system actions like
                creating , renaming, updating or deleting file or folder and
                project running status update in real time among all
                collabroators.
              </li>
              <li>
                <strong>Activity Logs:</strong>: See all activity of your
                collaborators in activity logs of your project.
              </li>
              <li>
                <strong>Chat Room:</strong> Each project has a chat room
                associated for devlopers to discuss project ideas and solutions.
              </li>
            </ul>
          </div>

          <div className="feature">
            <h3>Run</h3>
            <ul>
              <li>
                <strong>Project URL:</strong> Run your nodejs express project
                easily with, we wil provide you your project url
              </li>
              <li>
                <strong>Console Logs:</strong> View console output and errors in
                real time in console log section
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
