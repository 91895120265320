import React from 'react'
import { motion } from "framer-motion";

export default function SkillHeading() {
    const wrapperVariants = {
        initial: {
          border: "2px solid rgba(7, 139, 255, 1)",
          padding: "0px",
        },
        animate: {
          border: "2px solid rgba(7, 139, 255, 0)",
          padding: "15px",
          transition: {
            duration: 1,
            ease: "easeInOut",
            repeat: Infinity,
            // repeatType: "reverse",
          },
        }
      };
    
      const headingVariants = {
        initial: {
            marginTop: '15px',
            marginBottom: '15px'
        },
        animate: {
            marginTop: '0px',
            marginBottom: '0px',
            transition:{
                duration: 1,
                ease: "easeInOut",
                repeat: Infinity,
                // repeatType: "reverse",
            }
        }
      }
      
  return (
    <>
      <motion.div className="skill-section-heading" variants={headingVariants} initial="initial" animate="animate">
        <motion.div className="skill-section-heading-wrapper" variants={wrapperVariants} initial="initial" animate="animate">
          <div className="skill-section-heading-border">
            <h1>My Skills</h1>
          </div>
        </motion.div>
      </motion.div>
    </>
  )
}
