import React from "react";
import { motion } from "framer-motion";

export default function ProjectSubHeading({subHeading}) {
    const wrapperVariants = {
        initial: {
          x:0
        },
        animate: {
          x:10,
          transition: {
            duration: 1.5,
            ease: "easeInOut",
            repeat: Infinity,
            repeatType: 'reverse'
          },
        }
      };

  return (
    <div className="project-subheading">
      <motion.div className="subheading-name-wrapper" variants={wrapperVariants} initial='initial' animate="animate">
        <h2>{subHeading}</h2>
      </motion.div>
    </div>
  );
}
