// Globe.js
import React, { useRef, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { TextureLoader, RepeatWrapping } from 'three';
import { useLoader } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';

const Globe = ({ textureUrl, shouldRotate, radius }) => {
  const texture = useLoader(TextureLoader, textureUrl);
  const globeRef = useRef();

  // Set texture wrapping and repeat
  texture.wrapS = RepeatWrapping;
  texture.wrapT = RepeatWrapping;
  texture.repeat.set(3,1); 

  useFrame(() => {
    if (shouldRotate) {
      globeRef.current.rotation.y += 0.05;
    }
  });

  return (
    <mesh ref={globeRef}>
      <sphereGeometry args={[radius, 32, 32]} />
      <meshStandardMaterial map={texture} />
    </mesh>
  );
};

const GlobeScene = ({ textureUrl, shouldRotate, radius }) => {
  return (
    <Canvas>
      <ambientLight intensity={0.5} />
      <directionalLight position={[5, 5, 5]} />
      <Globe textureUrl={textureUrl} shouldRotate={shouldRotate} radius={radius} />
    </Canvas>
  );
};

export default GlobeScene;
