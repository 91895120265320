import React from 'react'
import ProjectSubHeading from "../../../projectSubHeading";

export default function TechStack() {
  return (
    <>
      <ProjectSubHeading  subHeading={"Tech Stack"}/>
      <div className="techStack project-text">
         <div className="techStack-item">
            <h3>Front-End</h3>
            <ul>
                <li><strong>Languages: </strong>Javascript</li>
                <li><strong>MarkUp and Styling: </strong>HTML, CSS, Bootstrap</li>
                <li><strong>Libraries:</strong> React</li>
                <li><strong>State Management: </strong>Redux</li>
            </ul>
         </div>
         <div className="techStack-item">
            <h3>Back-End</h3>
            <ul>
               <li><strong>Languages: </strong>Javascript</li>
                <li><strong>Runtime Environment:</strong> Node.js</li>
                <li><strong>Framework: </strong>Express</li>
                <li><strong>Database: </strong>MongoDB</li>
            </ul>
         </div>
      </div>
    </>
  )
}
