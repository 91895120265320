import React from "react";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ProjectSubHeading from "../../../projectSubHeading";

export default function Screenshots() {
  return (
    <>
     <ProjectSubHeading subHeading={"Screenshots"} />
     <Carousel showThumbs={true} autoPlay infiniteLoop>
        <div>
          <img src="/devcollab/feature1.png"/>
        </div>
        <div>
          <img src="/devcollab/feature2.png"/>
        </div>
        <div>
          <img src="/devcollab/feature3.png"/>
        </div>
        <div>
          <img src="/devcollab/feature4.png"/>
        </div>
        <div>
          <img src="/devcollab/feature5.png"/>
        </div>
      </Carousel>
    </>
  );
}
