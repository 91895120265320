import React from "react";
import ProjectSubHeading from "../../../projectSubHeading";

export default function Features() {
  return (
    <>
      <ProjectSubHeading subHeading="Key Features" />
      <div className="features-wrapper project-text">
        <div className="features">
          <div className="feature">
            <h3>User Authentication & Account Management</h3>
            <ul>
              <li>
                <strong>Three Login Pages:</strong> Separate login pages for
                users, staff, and owners.
              </li>
              <li>
                <strong>Google Sign-In:</strong> Users can sign in using their
                Google accounts.
              </li>
              <li>
                <strong>Logout:</strong> Secure logout functionality for all
                user roles.
              </li>
            </ul>
          </div>
          
          <div className="feature">
            <h3>User Interface</h3>
            <ul>
              <li>
                <strong>View Menu:</strong> Users can browse the available menu
                items.
              </li>
              <li>
                <strong>Add Items to Cart:</strong> Users can add items to their
                cart before placing an order.
              </li>
              <li>
                <strong>Payment:</strong> Integrated payment gateway for order
                transactions.
              </li>
              <li>
                <strong>Place Order:</strong> Users can place their orders
                seamlessly.
              </li>
              <li>
                <strong>Track Order:</strong> Users can track the status of
                their orders in real-time.
              </li>
              <li>
                <strong>Ratings and Suggestions:</strong> Users can rate their
                orders and provide suggestions after order completion.
              </li>
            </ul>
          </div>

          <div className="feature">
            <h3>Owner Interface</h3>
            <ul>
              <li>
                <strong>Manage Staff:</strong> Owners can add or remove staff
                members.
              </li>
              <li>
                <strong>User Feedback:</strong> Owners can view feedback
                provided by users.
              </li>
              <li>
                <strong>Order Details:</strong> Owners can view detailed
                information about all orders.
              </li>
              <li>
                <strong>Edit Menu:</strong> Owners can add or remove items from
                the menu, ensuring it is up-to-date.
              </li>
            </ul>
          </div>

          <div className="feature">
            <h3>Staff Interface</h3>
            <ul>
              <li>
                <strong>View User Feedback:</strong> Staff can access and read
                user feedback.
              </li>
              <li>
                <strong>Order Management:</strong> Staff can view, accept, or
                decline orders.
              </li>
              <li>
                <strong>Set Delivery Time:</strong> Staff can set the estimated
                delivery time, visible to users on their order tracking page.
              </li>
              <li>
                <strong>Cancellation Requests:</strong> Staff can approve or
                decline order cancellation requests made by users.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
