import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import GalaxyBackground from "./components/galaxyBackground";
import MySkills from "./components/skills/mySkills";
import Header from "./components/home/header";
import MyProjects from "./components/projects/myProjects";
import ContactUs from "./components/contact/contactUs";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Header/>} />
          <Route path="/skills" element={<MySkills/>} />
          <Route path="/projects/*" element={<MyProjects/>} />
          <Route path="/contact" element={<ContactUs/>} />
        </Routes>
      </BrowserRouter>
      <GalaxyBackground />
    </div>
  );
}

export default App;
