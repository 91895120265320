import React, { useEffect } from 'react';
import p5 from 'p5';

export default function GalaxyBackground() {
  
  useEffect(() => {
    let sketch = (p) => {
      let stars = [];

      p.setup = () => {
        p.createCanvas(window.innerWidth, window.innerHeight);
        for (let i = 0; i < 400; i++) {
          stars.push(new Star());
        }
      };

      p.draw = () => {
        p.background(0, 0, 0); // Change background color here
        p.translate(p.width / 2, p.height / 2);
        for (let star of stars) {
          star.update();
          star.show();
        }
      };

      class Star {
        constructor() {
          this.x = p.random(-p.width, p.width);
          this.y = p.random(-p.height, p.height);
          this.z = p.random(p.width);
          this.pz = this.z;
        }

        update() {
          this.z -= 10;
          if (this.z < 1) {
            this.z = p.width;
            this.x = p.random(-p.width, p.width);
            this.y = p.random(-p.height, p.height);
            this.pz = this.z;
          }
        }

        show() {
          p.fill(255); // Change particle color here
          p.noStroke();

          let sx = p.map(this.x / this.z, 0, 1, 0, p.width);
          let sy = p.map(this.y / this.z, 0, 1, 0, p.height);

          let r = p.map(this.z, 0, p.width, 8, 0);
          p.ellipse(sx, sy, r, r);

          let px = p.map(this.x / this.pz, 0, 1, 0, p.width);
          let py = p.map(this.y / this.pz, 0, 1, 0, p.height);

          this.pz = this.z;

          p.stroke(255);
          p.line(px, py, sx, sy);
        }
      }
    };

    let canvas = new p5(sketch);

    return () => {
      canvas.remove();
    };
  }, []);

  return null; // Return null since p5.js manages the canvas
}
