import React from "react";
import ProjectSubHeading from "../../../projectSubHeading";

export default function Description() {
  return (
    <>
      <ProjectSubHeading subHeading="Breif Description" />
      <div className="project-text">
        DineFusion is a comprehensive canteen management system that streamlines
        operations for users, staff, and owners. It facilitates everything from
        viewing menus and placing orders to managing staff and handling user
        feedback, all through a user-friendly interface.
      </div>
    </>
  );
}
