import React from "react";
import "../../styles/myskillsStyles.css";
import SkillHeading from "./skillHeading";
import SkillGlobes from "./skillGlobes";

export default function MySkills() {

  return (
    <div className="skill-section">
      <SkillHeading />
      <SkillGlobes />
    </div>
  );
}
