import React from "react";
import ProjectSubHeading from "../../../projectSubHeading";

export default function Description() {
  return (
    <>
      <ProjectSubHeading subHeading="Breif Description" />
      <div className="project-text">
        DevCollab is a full-featured collaborative platform designed
        specifically for Node.js Express projects. It streamlines project creation,
        collaboration, devlopment, and management, making it an essential tool
        for developers working in teams.
      </div>
    </>
  );
}
