import React from "react";
import "../../styles/myProjectsStyles.css";
import Devcollab from "./projectDetails/devcollab"
import Dinefusion from "./projectDetails/dinefusion"
import { Routes, Route} from 'react-router-dom'

export default function MyProjects() {

  return (
    <div className="my-projects-section">
      <Routes>
          <Route path='devcollab' element={<Devcollab title="devcollab" />}/>
          <Route path='dinefusion' element={<Dinefusion title="dinefusion" />}/>
      </Routes>
    </div>
  );
}
