import React from "react";
import ProjectSubHeading from "../../../projectSubHeading";

export default function LiveDemo() {
  return (
    <>
    <ProjectSubHeading subHeading="Live-Demo" />
    <div className="livedemo">
        <div>
      <button className="livedemo-btn" onClick={()=>window.open("https://devcollab-t811.onrender.com", "_blank")}>
        <span>
            Visit
        </span>
      </button>
      </div>
    </div>
    </>
  );
}
