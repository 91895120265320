import React from "react";
import { useNavigate } from "react-router";
import { motion, transform } from "framer-motion";
import "../../styles/navstyles.css";

export default function Navbar() {
  const navigate = useNavigate();
  const wrapperVariants = {
    initial: {
      border: "2px solid rgba(7, 139, 255, 1)",
      padding: "0px",
    },
    animate: {
      border: "2px solid rgba(7, 139, 255, 0)",
      padding: "10px",
      transition: {
        duration: 1,
        ease: "easeInOut",
        repeat: Infinity,
        // repeatType: "reverse",
      },
    },
    hoverL: {
        transform: 'translateX(20px) translateY(20px)',
    },
    hoverC: {
        transform: 'translateY(20px)',
    },
    hoverR: {
        transform: 'translateX(-20px) translateY(20px)',
    }
  };

  const navbarVariants = {
    initial: {
        marginTop: '10px',
        marginBottom: '10px'
    },
    animate: {
        marginTop: '0px',
        marginBottom: '0px',
        transition:{
            duration: 1,
            ease: "easeInOut",
            repeat: Infinity,
            // repeatType: "reverse",
        }
    }
  }

  return (
      <motion.div className="navbar" variants={navbarVariants} initial="initial" animate="animate">
        <motion.div
          className="wrapper"
          variants={wrapperVariants}
          initial="initial"
          animate="animate"
          whileHover="hoverL"
        >
          <motion.div className="nav-wrapper nav-skill-wrapper" onClick={()=>navigate('/skills')}>
            <div className="nav-skill">My Skills</div>
          </motion.div>
        </motion.div>
        <motion.div
          className="wrapper"
          variants={wrapperVariants}
          initial="initial"
          animate="animate"
          whileHover="hoverC"
        >
          <motion.div className="nav-wrapper nav-projects-wrapper" onClick={()=>navigate('/projects/devcollab')}>
            <div className="nav-projects">My Projects</div>
          </motion.div>
        </motion.div>
        <motion.div
          className="wrapper"
          variants={wrapperVariants}
          initial="initial"
          animate="animate"
          whileHover="hoverR"
        >
          <motion.div className="nav-wrapper nav-contact-wrapper" onClick={()=>navigate('/contact')} >
            <div className="nav-contact">Contact Me</div>
          </motion.div>
        </motion.div>
      </motion.div>
  );
}
