import React from 'react'
import ContactHeading from './contactHeading'
import '../../styles/contactStyles.css'
import ContactTile from './contactTiles'

export default function ContactUs() {
  return (
    <>
        <ContactHeading/>
        <ContactTile/>
    </>
  )
}
