import React, { useEffect, useRef } from "react";
import GlobeScene from "./globeScene";
import { motion } from "framer-motion";

export default function SkillGlobes() {
  const skills = [
    {
      content: "HTML",
      url: "/images/HTML.png",
      radius: 3,
      shouldRotate: true,
    },
    {
      content: "CSS",
      url: "/images/CSS.png",
      radius: 3,
      shouldRotate: true,
    },
    {
      content: "JavaScript",
      url: "/images/javascript.png",
      radius: 3,
      shouldRotate: true,
    },
    {
      content: "ReactJs",
      url: "/images/reactjs.png",
      radius: 3,
      shouldRotate: true,
    },
    {
      content: "Node.js",
      url: "/images/nodejs.png",
      radius: 3,
      shouldRotate: true,
    },
    {
      content: "PugJs",
      url: "/images/pugjs.png",
      radius: 3,
      shouldRotate: true,
    },
    {
      content: "MongoDB",
      url: "/images/mongodb.png",
      radius: 3,
      shouldRotate: true,
    },
    {
      content: "Bootstrap",
      url: "/images/bootstrap.png",
      radius: 3,
      shouldRotate: true,
    },
  ];

  const n = skills.length;

  useEffect(() => {
    // Create a new <style> element
    const styleElement = document.createElement("style");
    document.head.appendChild(styleElement);
    const styleSheet = styleElement.sheet;

    if (styleSheet) {
      for (let i = 0; i < n; i++) {
        const rule = `#skill-globe-wrapper-${i}::after {
                content: "${skills[i].content}";
            }`;
        styleSheet.insertRule(rule);
      }
    }

    // Cleanup function to remove the <style> element when the component unmounts
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  return (
    <div className="skill-globe-container">
      {skills.map((skill, index) => {
        return (
          <motion.div
            initial={{y: 100000 }}
            animate={{y: 0 }}
            transition={{ duration: 0.5, ease: "easeOut", delay: 0.5 * index }}
          >
            <div
              className="skill-globe-wrapper"
              id={`skill-globe-wrapper-${index}`}
            >
              <GlobeScene
                textureUrl={skill.url}
                radius={skill.radius}
                shouldRotate={skill.shouldRotate}
              />
            </div>
          </motion.div>
        );
      })}
    </div>
  );
}
