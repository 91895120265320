import React from 'react'
import Navbar from './navbar'
import Intro from './intro'
import GalaxyBackground from '../galaxyBackground'

export default function Header() {
  return (
    <>
       <Navbar/>
       <Intro/>
       <GalaxyBackground/>
    </>
  )
}
