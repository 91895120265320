import React from "react";
import { motion } from "framer-motion";

export default function ContactTiles() {
  const tileVariant1 = {
    initial: {
      scaleX: 0
    },
    animate: {
      scaleX: 1,
      transition: {
        duration: 0.2,
        ease: "easeInOut",
        delay: 0
      }
    }
  };

  const tileVariant2 = {
    initial: {
      scaleX: 0
    },
    animate: {
      scaleX: 1,
      transition: {
        duration: 0.2,
        ease: "easeInOut",
        delay: 0.2
      }
    }
  };

  const tileVariant3 = {
    initial: {
      scaleX: 0
    },
    animate: {
      scaleX: 1,
      transition: {
        duration: 0.2,
        ease: "easeInOut",
        delay: 0.4
      }
    }
  };

  return (
    <div className="contact-tiles-wrapper">
      <div className="contact-tiles">
        <motion.div
          className="contact-tile email"
          variants={tileVariant1}
          initial="initial"
          animate="animate"
          onClick={()=>window.open("mailto:manassrivastava567@gmail.com", "_blank")}
        >
          <span>
            <img src="/contactIcons/email.png" alt="" />
          </span>
          <span>Email</span>
        </motion.div>

        <motion.div
          className="contact-tile linkedin"
          variants={tileVariant2}
          initial="initial"
          animate="animate"
          onClick={()=>window.open("https://www.linkedin.com/in/manassrivastavaofficial", "_blank")}
        >
          <span>
            <img src="/contactIcons/linkedin.png" alt="" />
          </span>
          <span>LinkedIn</span>
        </motion.div>

        <motion.div
          className="contact-tile stackoverflow"
          variants={tileVariant3}
          initial="initial"
          animate="animate"
          onClick={()=>window.open("https://stackoverflow.com/users/25623844/nodenomad", "_blank")}
        >
          <span>
            <img src="/contactIcons/stackoverflow.png" alt="" />
          </span>
          <span>Stack Overflow</span>
        </motion.div>
      </div>
    </div>
  );
}
