import React from "react";
import ProjectSection from "../../projectSection";
import Description from "./sections/description";
import Features from "./sections/features";
import TechStack from "./sections/techstack";
import Screenshots from "./sections/screenshots";
import LiveDemo from "./sections/liveDemo";
import ProjectHeading from "../../projectHeading";
import ProjectNavigation from "../../projectNavigation";

export default function Dinefusion({title}) {
  function capitalize(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  return (
    <>
      <ProjectHeading heading={capitalize(title)} />
      <ProjectNavigation />
      <ProjectSection>
        <Description />
      </ProjectSection>
      <ProjectSection>
        <Features />
      </ProjectSection>
      <ProjectSection>
        <Screenshots />
      </ProjectSection>
      <ProjectSection>
        <TechStack />
      </ProjectSection>
      <ProjectSection>
        <LiveDemo />
      </ProjectSection>
    </>
  );
}
